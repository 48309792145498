import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import Header from "./Header";
import { GET_CURRENT_USER_URL } from "../utils/URLS";
import axios from "axios";
import { useUserContext } from "../Context/UserContext";
import LoadingSpinner from "./ui/LoadingSpinner";

export default function Layout({ children }) {
  const navigate = useNavigate();
  const [user, setUser] = useUserContext();
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    if (!user) {
      const token = localStorage.getItem("token");
      const email = localStorage.getItem("email");

      if (token && email) {
        async function checkLogin() {
          try {
            const response = await axios.get(GET_CURRENT_USER_URL, {
              headers: {
                Authorization: `Token ${token}`,
              },
            });
            setUser(response.data);
            if (response.data.groups.includes("administrator")) {
              // navigate("/dashboard");
              if (pathname === "/") navigate("/dashboard");
              else navigate(pathname);
            } else {
              // navigate("/companies");
              if (pathname === "/") navigate("/companies");
              else navigate(pathname);
            }
            setLoading(false);
          } catch (err) {
            if (err.response.status === 401) {
              navigate("/signin");
            }
          }
        }
        checkLogin();
      } else {
        navigate("/signin");
      }
    } else {
      setLoading(false);
    }

    // eslint-disable-next-line
  }, []);
  return (
    <>
      {loading ? (
        <div className="flex h-screen items-center justify-center">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="flex min-h-screen grid-cols-[250px_1fr] bg-[#F1F2F6] md:grid-cols-[350px_1fr]">
          <Sidebar />
          <div className="flex flex-1 flex-col gap-10 p-10">
            <Header />
            <Outlet />
          </div>
        </div>
      )}
    </>
  );
}
