import { Card, Button } from "antd";  // Import Button component from antd
import { useState } from "react";  // Import useState hook for managing collapse state

const TaskCard = ({ onDragStart, onDragEnd, onClick, ...props }) => {
    const [isExpanded, setIsExpanded] = useState(false);  // State to manage expand/collapse

    return (
        <Card
            style={{ width: "100%", cursor: "pointer" }}
            id={props.id}
            draggable
            onDragStart={(e) => onDragStart(e)}
            onDragEnd={(e) => onDragEnd(e)
                
            }

            actions={props.status !== "Done" && [
                <div key="actions">
                <Button type="default" onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? "Collapse Details" : "Expand Details"}
                </Button>
                <Button type="default" onClick={() => onClick(props)}>
                    Edit Task
                </Button>
                </div>
            ]}
        >
            {/* Basic Info Table */}
            <h3><b>{props.title || "Title"}</b></h3>
            <div style={{ width: '100%' }}>
                <table style={{ width: '100%', borderCollapse: 'collapse', border: 'none' }}>
                    <tbody>
                        <tr style={{ borderBottom: '1px dotted #ddd' }}>
                            <td style={{ fontWeight: 'bold', padding: '4px 8px', border: 'none', lineHeight: '1.2' }}>Task #:</td>
                            <td style={{ padding: '4px 8px', border: 'none', lineHeight: '1.2' }}>{props.id}</td>
                        </tr>
                        <tr style={{ borderBottom: '1px dotted #ddd' }}>
                            <td style={{ fontWeight: 'bold', padding: '4px 8px', border: 'none', lineHeight: '1.2' }}>Author:</td>
                            <td style={{ padding: '4px 8px', border: 'none', lineHeight: '1.2' }}>{props.created_by_first_name}</td>
                        </tr>
                        <tr style={{ borderBottom: '1px dotted #ddd' }}>
                            <td style={{ fontWeight: 'bold', padding: '4px 8px', border: 'none', lineHeight: '1.2' }}>Assigned To:</td>
                            <td style={{ padding: '4px 8px', border: 'none', lineHeight: '1.2' }}>{props.assigned_to_first_name}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* Expandable Section */}
            {isExpanded && (
                <div style={{ width: '100%', marginTop: '10px' }}>
                    <table style={{ width: '100%', borderCollapse: 'collapse', border: 'none' }}>
                        <tbody>
                            {props.status !== "Done" && (
                                <tr style={{ borderBottom: '1px dotted #ddd' }}>
                                    <td style={{ fontWeight: 'bold', padding: '4px 8px', border: 'none', lineHeight: '1.2' }}>Created On:</td>
                                    <td style={{ padding: '4px 8px', border: 'none', lineHeight: '1.2' }}>{props.created_date ? new Date(props.created_date).toLocaleDateString('en-GB') : 'N/A'}</td>
                                </tr>
                            )}
                            {props.status !== "Done" && (
                                <tr style={{ borderBottom: '1px dotted #ddd' }}>
                                    <td style={{ fontWeight: 'bold', padding: '4px 8px', border: 'none', lineHeight: '1.2' }}>Due On:</td>
                                    <td style={{ padding: '4px 8px', border: 'none', lineHeight: '1.2' }}>{props.due_date ? new Date(props.due_date).toLocaleDateString('en-GB') : 'N/A'}</td>
                                </tr>
                            )}
                            {props.status !== "Done" && (
                                <tr style={{ borderBottom: '1px dotted #ddd' }}>
                                    <td style={{ fontWeight: 'bold', padding: '4px 8px', border: 'none', lineHeight: '1.2' }}>Priority:</td>
                                    <td style={{ padding: '4px 8px', border: 'none', lineHeight: '1.2' }}>{props.priority}</td>
                                </tr>
                            )}
                            {props.status !== "Done" && (
                                <tr style={{ borderBottom: '1px dotted #ddd' }}>
                                    <td style={{ fontWeight: 'bold', padding: '4px 8px', border: 'none', lineHeight: '1.2' }}>Mail Sent:</td>
                                    <td style={{ padding: '4px 8px', border: 'none', lineHeight: '1.2' }}>{props.mail_sent ? 'Yes' : 'No'}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </Card>
    );
};

export default TaskCard;
