import React, { useState, useEffect } from "react";
import { cn } from "../../utils/lib";
import { ReactComponent as Eye } from "../../assets/SVG/eye.svg";
import { ReactComponent as Close } from "../../assets/SVG/close.svg";
import TreeViewer from "./FileExplorer";
import ReminderViewer from "./reminder/ReminderViewer";
import UserViewer from "./users/UserViewer";
import TasksViewer from "./tasks/TasksViewer";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const DETAILS_TAB = "details";
const FILES_TAB = "files";
const S_FILES_TAB = "s_files";
const TASKS_TAB = "tasks";
const REMINDERS_TAB = "reminders";
const USERS_TAB = "users";

export default function CompanyDetailsButton({ company }) {
  const [show, setShow] = useState(false);
  const [tab, setTab] = useState(DETAILS_TAB);
  const [serviceNames, SetServicesNames] =useState([]);
  const [notes, SetNotes] = useState('');
  useEffect(() => {
    if (company) {
      console.log(company)
      SetServicesNames(company?.services)
      if (company?.notes){
        SetNotes(company?.notes)
      }
    }
  }
  )
  const handleShow = () => {
    setShow(true);
    setTab(DETAILS_TAB);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleTabChange = (selectedTab) => {
    setTab(selectedTab);
  };

  return (
    <>
      <button className="" onClick={handleShow}>
        <Eye className="h-8 w-8 text-gray-600" />
      </button>
      <div
        style={{ zIndex: 1 }}
        className={cn(
          "z-1000 fixed right-0 top-0 flex h-screen w-[85%] flex-col gap-16 bg-white p-10 shadow transition duration-1000 auto_scroll",
          {
            "translate-x-0": show,
            "translate-x-full": !show,
          },
        )}
      >
        <div className="flex items-center justify-between">
          <span className="text-3xl font-bold" onClick={handleShow}>{company.company_name}</span>
          <div>
            <button onClick={handleClose}>
              <Close className="h-8 w-8" />
            </button>
          </div>
        </div>
        <div>
        <Stack direction="row" spacing={1}>
          {serviceNames.map((data) => (
            data?.name && <Chip key={data?.id} label={data?.name} />
          ))}
        </Stack>
        {notes && (
          <div>
            <h4 className="highlight-line">{notes}</h4>
          </div>
        )}
        </div>
        <div className="flex justify-between gap-1">
          <div className="flex gap-1">
            <button
              className={cn(
                "border border-gray-300 bg-gray-200 px-5 py-4 shadow-sm",
                {
                  "border-b-white bg-golden text-white shadow-inner":
                    tab === DETAILS_TAB,
                },
              )}
              onClick={() => handleTabChange(DETAILS_TAB)}
            >
              Company Details
            </button>
            <button
              className={cn(
                "border border-gray-300 bg-gray-200 px-5 py-4 shadow-sm",
                {
                  "border-b-white bg-golden text-white shadow-inner":
                    tab === S_FILES_TAB,
                },
              )}
              onClick={() => handleTabChange(S_FILES_TAB)}
            >
              SLCS Secretarial
            </button>
            <button
              className={cn(
                "border border-gray-300 bg-gray-200 px-5 py-4 shadow-sm",
                {
                  "border-b-white bg-golden text-white shadow-inner":
                    tab === FILES_TAB,
                },
              )}
              onClick={() => handleTabChange(FILES_TAB)}
            >
              SLCS Accounting
            </button>
            <button
              className={cn(
                "border border-gray-300 bg-gray-200 px-5 py-4 shadow-sm",
                {
                  "border-b-white bg-golden text-white shadow-inner":
                    tab === TASKS_TAB,
                },
              )}
              onClick={() => handleTabChange(TASKS_TAB)}
            >
              Tasks
            </button>
            <button
              className={cn(
                "border border-gray-300 bg-gray-200 px-5 py-4 shadow-sm",
                {
                  "border-b-white bg-golden text-white shadow-inner":
                    tab === REMINDERS_TAB,
                },
              )}
              onClick={() => handleTabChange(REMINDERS_TAB)}
            >
              Reminders
            </button>
            {/* <button
              className={cn(
                "border border-gray-300 bg-gray-200 px-5 py-4 shadow-sm",
                {
                  "border-b-white bg-golden text-white shadow-inner":
                    tab === USERS_TAB,
                },
              )}
              onClick={() => handleTabChange(USERS_TAB)}
            >
              Users
            </button> */}
          </div>
        </div>
        {tab === TASKS_TAB && <TasksViewer company_id={company.id} />}
        {tab === FILES_TAB && <TreeViewer id={company.id} name={company.company_name} folder_id={'01QKQUJJM2ZH2EBQ77OZCJMTSZTWNABW6T'} />}
        {tab === S_FILES_TAB && <TreeViewer id={company.id} name={company.company_name} folder_id={'01QKQUJJKH5CPZ2JROBBALBIMAFBWK52BS'}/>}
        {tab === REMINDERS_TAB && <ReminderViewer company_id={company.id} />}
        {tab === USERS_TAB && <UserViewer company={company} />}
        {tab === DETAILS_TAB && (
          <table className="w-full">
            <tbody>
              {rows.map(({ label, key }, index) => {
                if (index % 2 === 0) {
                  return (
                    <tr className="border-b text-sm" key={index}>
                      <td className="py-3 text-gray-500">{label}:</td>
                      <td className="py-3 font-semibold text-slate-700">
                        {company[key]}
                      </td>
                      {rows[index + 1] && (
                        <>
                          <td className="py-3 text-gray-500">
                            {rows[index + 1].label}:
                          </td>
                          <td className="py-3 font-semibold text-slate-700">
                            {rows[index + 1].key === "company_logo" ? (
                              <>
                                {company[rows[index + 1].key] ? (
                                  <img
                                    src={company[rows[index + 1].key]}
                                    alt={company.company_name}
                                    className="h-12 w-12 rounded-full object-cover"
                                  />
                                ) : (
                                  <div className="flex h-12 w-12 items-center justify-center text-3xl font-bold text-gray-700">
                                    {company.company_name
                                      .charAt(0)
                                      .toUpperCase()}
                                  </div>
                                )}
                              </>
                            ) : (
                              company[rows[index + 1].key]
                            )}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                } else {
                  return <></>;
                }
              })}
              {/* <tr className="border-b">
                <td className="py-3">
                  <span className="text-gray-500">Company Name:</span>
                </td>
                <td className="py-3">
                  <span className="font-semibold text-slate-700">
                    {company_name}
                  </span>
                </td>
                <td className="py-3">
                  <span className="text-gray-500">Company Logo:</span>
                </td>
                <td className="py-3">
                  <span className="font-semibold text-slate-700">LOGO</span>
                </td>
              </tr> */}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
}

const rows = [
  {
    label: "Company Incorporation Number (CI)",
    key: "ci_number",
  },
  {
    label: "Company Incorporated On",
    key: "incorporation_date",
  },
  {
    label: "Business Registration Number (BR) also known as TIN Number",
    key: "br_number",
  },
  {
    label: "Company Financial Year End Date",
    key: "company_financial_end_date",
  },
  {
    label: "Last Business Registration Renewed On",
    key: "last_business_registration",
  },
  {
    label: "Next Business Registration Due On",
    key: "next_business_registration_renewal_due",
  },
  {
    label: "Last Annual Return Renewed (AR1) On ",
    key: "last_annual_return",
  },
  {
    label: "Next Annual Return (AR1) Due On",
    key: "next_annual_return_due",
  },
  {
    label: "Last Annual General Meeting (AGM) Held On",
    key: "last_agm_signed",
  },
  {
    label: "Next Annual General Meeting (AGM) Due On",
    key: "next_agm_deadline",
  },
  {
    label: "Last Audited Financial Statement (AFS) Filed",
    key: "last_audited_financial_statement",
  },
  {
    label: "Next Audited Financial Statement (AFS) Due",
    key: "next_audited_financial_statement_deadline",
  },
  {
    label: "Last Profit Tax Return (PTR) Filed",
    key: "last_profit_tax_return",
  },
  {
    label: "Next Profit Tax Return (PTR) Due",
    key: "profit_tax_return_deadline",
  },
  {
    label: "Dedicated Account Officer",
    key: "account_officer_name",
  },
  {
    label: "Dedicated Account Officer Email Address",
    key: "account_officer_email",
  },
  {
    label: "Dedicated Company Secretarial Officer",
    key: "company_secretarial_name",
  },
  {
    label: "Dedicated Company Secretarial Officer Email Address",
    key: "company_secretarial_email",
  },
  {
    label: "Manager Name",
    key: "manager_name",
  },
  {
    label: "Associate Auditor",
    key: "associate_auditor",
  },
  {
    label: "HubDoc Email Address",
    key: "hub_doc_email_address",
  },
  {
    label: "Business Principal Activity",
    key: "business_principal_activity",
  },
];

const styles = `
  .chip {
    display: inline-block;
    padding: 0 25px;
    height: 50px;
    font-size: 16px;
    line-height: 50px;
    border-radius: 25px;
    background-color: #f1f1f1;
    margin: 5px;
  }

  .chip img {
    float: left;
    margin: 0 10px 0 -25px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
`;