import { Link, useLocation } from "react-router-dom";
import { cn } from "../utils/lib";
import { useUserContext } from "../Context/UserContext";
import { ReactComponent as Dashboard } from "../assets/SVG/dashboard.svg";
import { ReactComponent as Admin } from "../assets/SVG/admin.svg";
import { ReactComponent as Business } from "../assets/SVG/business.svg";
import { ReactComponent as Notifications } from "../assets/SVG/notifications.svg";
import { ReactComponent as Logout } from "../assets/SVG/logout.svg";
import Logo from "../assets/images/shenlaroche.png";
import LoadingSpinner from "./ui/LoadingSpinner";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GET_ADMIN_URL} from "../utils/URLS";

export default function Sidebar() {
  const [user, setUser] = useUserContext();
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (user.groups.includes("administrator")) {
        setAdmin(true);
        setLoading(false);
      }
    }
  }, [user]);

  const handleSignOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    setUser(null);
    window.location.href = "https://shenlaroche.com/";
  };

  return (
    <div className="sticky top-0 flex h-screen w-[250px] flex-col md:w-[350px]">
      <div className="flex h-36 items-center justify-center bg-white" style={{"padding":40}}>
        <div className="p-4 text-2xl font-black uppercase" >
          <img src={Logo} alt="LOGO" />
        </div>
      </div>
      <div className="flex flex-1 flex-col gap-2 bg-gradient-to-t from-[#1B1B1B] to-[#37383B] p-5">
        {loading ? (
          <div className="flex h-full w-full items-center justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            
              <NavLink to="/dashboard">
                <div className="flex items-center gap-4">
                  <Dashboard className="h-7 w-7" />
                  <span>Dashboard</span>
                </div>
              </NavLink>
            
            <NavLink to="/companies">
              <div className="flex items-center gap-4">
                <Business className="h-7 w-7" />
                <span>Companies</span>
              </div>
            </NavLink>
            {admin && (
              <>
                <NavLink to="/notifications">
                  <div className="flex items-center gap-4">
                    <Notifications className="h-7 w-7" />
                    <span>Notifications</span>
                  </div>
                </NavLink>
               
                  <div className="flex items-center gap-4">
                    <Admin className="h-7 w-7" style={{"color":"white"}} />
                    <span style={{"color":"white","width": "80%", "margin": "0 auto"}}><a href="https://portal.shenlaroche.online/admin">Administration</a></span>
                  </div>
                  {/* width: 88%;
    margin: 0 auto; */}
               
              </>
            )}

            <button
              onClick={handleSignOut}
              className="rounded-md px-5 py-4 font-medium leading-normal text-white transition hover:bg-[#4e4f52]"
            >
              <div className="flex items-center gap-4">
                <Logout />
                <span>Sign Out</span>
              </div>
            </button>
          </>
        )}
      </div>
    </div>
  );
}

const NavLink = ({ to, children }) => {
  const { pathname } = useLocation();
  return (
    <Link
      to={to}
      className={cn(
        "rounded-md px-5 py-4 font-medium leading-normal text-white transition hover:bg-[#4e4f52]",
        {
          "bg-golden text-white": pathname === to,
        },
      )}
    >
      {children}
    </Link>
  );
};
