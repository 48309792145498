import { createContext, useContext, useState } from "react";

export const UserContext = createContext(false);

export function UserContextProvider({ children }) {
  const [user, setUser] = useState(null);
  return (
    <UserContext.Provider value={[user, setUser]}>
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  const [user, setUser] = useContext(UserContext);
  return [user, setUser];
}
