const API_ROOT = 'https://portal.shenlaroche.online' || "";
// const API_ROOT = 'http://127.0.0.1:8000' || "";
export const BASE_URL = API_ROOT + "/api/";
export const GET_USER_EXISTS_URL = `${BASE_URL}user/`;

export const SEND_AUTH_CODE_URL = `${BASE_URL}drf-passwordless-login/auth/email/`;
export const VERIFY_AUTH_CODE_URL = `${BASE_URL}drf-passwordless-login/auth/token/`;

export const GET_COMPANIES_URL = `${BASE_URL}company/`;
export const GET_USER_REMINDERS_URL = `${BASE_URL}get_user_reminders/`;
export const GET_TASK_URL = `${BASE_URL}task/`
export const CHECK_LOGIN_URL = `${BASE_URL}checklogin/`;
export const REGISTER_URL = `${BASE_URL}registration/`;

export const GET_CURRENT_USER_URL = `${BASE_URL}current/user`;
export const GET_NOTIFICATIONS_URL = `${BASE_URL}notifications/`;

export const FILES_URL = `${BASE_URL}customerfiles/`;
export const GET_ADMIN_URL = `${API_ROOT}/admin`;

export const TASK_FILE_URL = `${BASE_URL}task_files/`;
export const UPDATE_USER_URL = `${BASE_URL}deactivate/`;
export const ONE_DRIVE_FILES_URL = `${BASE_URL}get_one_drive_data/get_data/`;