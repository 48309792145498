import { useFormContext } from "react-hook-form";
import { cn } from "../../utils/lib";

export default function Input({ className, label, name, ...props }) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const errorMessage = errors[name]?.message?.toString();

  return (
    <div className="relative w-full">
      <div className="flex flex-col-reverse">
        <input
          id={name}
          {...register(name, { required: "This field is required" })}
          type="text"
          className={cn(
            "peer w-full rounded-lg border px-3 py-2 text-sm text-black outline-none focus:border-golden focus:bg-[#fefcfc]",
            className,
          )}
          {...props}
        />
        <label
          htmlFor={name}
          className="relative left-3 top-1.5 hidden w-fit cursor-pointer bg-white px-1 text-xs leading-none text-golden  peer-focus:block"
        >
          {label}
        </label>
      </div>
      {errorMessage && (
        <p className="mt-1 text-xs leading-normal text-red-500">
          {errorMessage}
        </p>
      )}
    </div>
  );
}
