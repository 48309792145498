import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Signin from "./pages/Signin/Signin";
import Verify from "./pages/Verify/Verify";
import Dashboard from "./pages/Dashboard/Dashboard";
import Layout from "./components/Layout";
import Adminstration from "./pages/Adminstration/Adminstration";
import UserManagement from "./pages/UserManagement/UserManagement";
import Notifications from "./pages/Notifications/Notifications";
import Companies from "./pages/Companies/Companies";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/companies",
        element: <Companies />,
      },
      {
        path: "/adminstration",
        element: <Adminstration />,
      },
      {
        path: "/user-management",
        element: <UserManagement />,
      },
      {
        path: "/notifications",
        element: <Notifications />,
      },
    ],
  },
  {
    path: "/signin",
    element: <Signin />,
  },
  {
    path: "/verify",
    element: <Verify />,
  },
]);

export default function MyRouter() {
  return <RouterProvider router={router} />;
}
