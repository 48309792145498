import React, { useState } from 'react';
import format from 'date-fns/format';
import parse from 'date-fns/parse';

const DateUpdateModal = ({isModalOpen, nextAnnualReturnDate, nextBusinessRegistrationRequest, setNextAnnualReturnDate, setNextBusinessRegistrationRequest, handleSave, closeModal }) => {
  const formatDate = (dateString) => {
    if (!dateString) return '';
    return format(parse(dateString, 'yyyy-MM-dd', new Date()), 'dd-MMM-yyyy');
  };

  const parseDate = (dateString) => {
    if (!dateString) return '';
    return format(parse(dateString, 'dd-MMM-yyyy', new Date()), 'yyyy-MM-dd');
  };

  const handleAnnualReturnDateChange = (e) => {
    const formattedDate = formatDate(e.target.value);
    setNextAnnualReturnDate(formattedDate);
  };

  const handleBusinessRegistrationRequestChange = (e) => {
    const formattedDate = formatDate(e.target.value);
    setNextBusinessRegistrationRequest(formattedDate);
  };

  
  return (
    isModalOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white p-6 rounded shadow-lg company-date-modal-width">
          <h2 className="mb-4 company-date-heading">Update Company Information</h2>
          <form>
            <div className='company-date-section'>
            <div className="mb-4 company-date">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nextAnnualReturnDate">
                Next Annual Return Date
              </label>
              <input
                type="date"
                id="nextAnnualReturnDate"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={parseDate(nextAnnualReturnDate)}
                onChange={handleAnnualReturnDateChange}
              />
            </div>
            <div className="mb-4 company-registration">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nextBusinessRegistrationRequest">
                Next Business Registration Request
              </label>
              <input
                type="date"
                id="nextBusinessRegistrationRequest"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={parseDate(nextBusinessRegistrationRequest)}
                onChange={handleBusinessRegistrationRequestChange}
              />
            </div>
            </div>
            <div className="flex items-center justify-center">
              <button
                type="button"
                onClick={handleSave}
                className="save-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Save
              </button>
              <button
                type="button"
                onClick={closeModal}
                className="bg-red-500 close-btn hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  );
};

export default DateUpdateModal;
