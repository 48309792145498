import React from "react";
import { cn } from "../../utils/lib";

export default function LoadingSpinner({ className, ...props }) {
  return (
    <div
      className={cn(
        "inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-golden border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]",
        className,
      )}
      {...props}
    ></div>
  );
}
