import React, { useRef, useState } from "react";
import Input from "../../components/ui/Input";
import { FormProvider, useForm, Controller } from "react-hook-form";
import bg from "../../assets/images/victoria_harbour_hong_kong_china.jpg";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import axios from "axios";
import { REGISTER_URL } from "../../utils/URLS";
import Logo from "../../assets/images/shenlaroche.png";

export default function Signup() {
  const methods = useForm();
  const acceptTerms = useRef(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const onSubmit = async (data) => {
    setError(null);
    if (!acceptTerms.current.checked) {
      setError("Please accept the terms and conditions");
      return;
    }

    const jsonData = JSON.stringify(data);

    try {
      await axios.post(REGISTER_URL, jsonData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setSuccess(true);
    } catch {
      setError("Error signing up, please try again!");
    }
  };

  return (
    <div className="flex h-screen items-center gap-10 p-4">
      <div className="h-full w-2/3">
        <img
          loading="lazy"
          src={bg}
          alt=""
          className="h-full w-full rounded-xl object-cover"
        />
      </div>
      <div className="flex w-1/3 flex-col items-start gap-5">
        <div>
          <img className="h-32 w-auto" src={Logo} alt="LOGO" />
        </div>
        {success ? (
          <div className="flex max-w-sm flex-col space-y-2 p-10 shadow">
            <p className="font-semibold">Thank you for registering. </p>
            <p>
              &#8226; You will now receive an email and text message to confirm
              your email address and mobile number.
            </p>
            <p>
              &#8226; Please click the link to verify. Once verified, a member
              of the ShenlaRoche staff will contact you.
            </p>
          </div>
        ) : (
          <>
            <div>
              <h4 className="text-3xl font-bold leading-normal text-black">
                Sign Up
              </h4>
              <p className="text-sm font-light text-gray-500">
                Enter your details to register
              </p>
            </div>

            <div>
              <FormProvider {...methods}>
                <form
                  onSubmit={methods.handleSubmit(onSubmit)}
                  className="flex flex-col items-center gap-1"
                >
                  <Input
                    label="Email"
                    name="email"
                    placeholder="Email address"
                    type="email"
                  />
                  <Input label="Name" name="name" placeholder="Your Name" />
                  <div className="mb-1">
                    <Controller
                      control={methods.control}
                      name="phone_number"
                      rules={{ required: "This field is required" }}
                      render={({ field: { onChange, value } }) => (
                        <PhoneInput
                          value={value}
                          onChange={onChange}
                          defaultCountry="hk"
                          placeholder="Enter phone number"
                          className="rounded-full"
                        />
                      )}
                    />
                  </div>

                  {methods.formState.errors.phoneNumber && (
                    <p className="mt-1 text-xs leading-normal text-red-500">
                      {methods.formState.errors.phoneNumber.message}
                    </p>
                  )}
                  <Input
                    label="Company Name"
                    name="company"
                    placeholder="Company Name"
                  />
                  <Input
                    label="Your role"
                    name="role"
                    placeholder="Your role in the company"
                  />
                  <div className="mr-auto flex w-full flex-col">
                    <div className="flex items-center gap-2">
                      <input
                        ref={acceptTerms}
                        type="checkbox"
                        id="agree"
                        className="cursor-pointer text-white checked:accent-golden"
                      />
                      <label
                        htmlFor="agree"
                        className="my-2 cursor-pointer text-xs"
                      >
                        I agree the Terms and Conditions
                      </label>
                    </div>
                    {error && (
                      <p className="mt-1 text-xs leading-normal text-red-500">
                        {error}
                      </p>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="my-2 w-full rounded-xl bg-golden py-3 font-semibold uppercase text-white transition hover:opacity-80 disabled:opacity-80"
                    disabled={methods.formState.isSubmitting}
                  >
                    Verify
                  </button>
                  <p className="text-sm font-light">
                    Already have an account?
                    <a
                      href="/signin"
                      className="mx-1 font-bold text-golden transition hover:underline"
                    >
                      Sign in
                    </a>
                  </p>
                </form>
              </FormProvider>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
