import React, { useState, useEffect } from "react";
import CompanyDetailsButton from "./CompanyDetailsButton";
import useTable, { TableFooter } from "../../hooks/useTable";
import axios from "axios";
import { GET_COMPANIES_URL } from "../../utils/URLS";
import LoadingSpinner from "../../components/ui/LoadingSpinner";

export default function Companies() {
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [companyCode, setCompanyCode] = useState("Company Code");
  const { slice, range } = useTable(filteredCompanies, page, 5);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(GET_COMPANIES_URL, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });
      setCompanies(response.data);
      setFilteredCompanies(response.data);
      setLoading(false);
    }
    fetchData();
  }, []);

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    const filtered = companies.filter((company) => {
      return company.company_name.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredCompanies(filtered);
    setPage(1);
  };

  const handleCompanyCodeChange = (event) => {
    const value = event.target.value;
    setCompanyCode(value);
    const filtered = companies.filter((company) => {
      return company.company_code === value;
    });
    setFilteredCompanies(filtered);
    setPage(1);
  };

  return (
    <div className="mt-16">
      <div className="flex flex-col gap-5 rounded-xl bg-white p-6 shadow">
        <div className="-mt-16 mb-5 rounded-xl bg-golden px-4 py-6 text-2xl font-semibold text-white shadow-md ">
          <p>Companies</p>
        </div>
        <div className="flex items-center gap-2">
          <div className="w-2/3">
            <input
              type="text"
              className="h-10 w-full cursor-pointer rounded border border-gray-300 px-3"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          <div className="w-1/3">
            <select
              defaultValue="Company Code"
              name="companyCode"
              value={companyCode}
              onChange={handleCompanyCodeChange}
              className="h-10 w-full cursor-pointer rounded border border-gray-300 bg-white px-3"
            >
              <option value="Company Code">Company Code</option>
              {/* {Array.from(Array(26), (_, index) => {
                const letter = String.fromCharCode(65 + index);
                return (
                  <option key={letter} value={letter}>
                    {letter}
                  </option>
                );
              })} */}
              <option value="M">M</option>
              <option value="N">N</option>
              <option value="D">D</option>
              <option value="N/A">N/A</option>
            </select>
          </div>
        </div>

        {loading ? (
          <div className="flex items-center justify-center py-5">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            {filteredCompanies.length === 0 && (
              <p className="tex-xl mt-4 text-center text-gray-500">
                No Companies are found.
              </p>
            )}

            <table className="w-full">
              <tbody>
                {slice.map((company, key) => {
                  return (
                    <tr
                      key={key}
                      className="flex items-center gap-12 border-t border-gray-300"
                    >
                      <td className="py-4">
                        <center>
                          <div className="flex h-28 w-28 items-center justify-center rounded-full bg-gray-300">
                            {company.company_logo ? (
                              <img
                                src={company.company_logo}
                                alt={company.company_name}
                                className=""
                              />
                            ) : (
                              <div className="flex h-full w-full items-center justify-center text-3xl font-bold text-gray-700">
                                {company.company_name.charAt(0).toUpperCase()}
                              </div>
                            )}
                          </div>
                        </center>
                      </td>
                      <td className="flex-1 py-4">
                        <div>
                          <p className="mb-1 font-semibold leading-none text-gray-700">
                            {company.company_name}
                          </p>
                          <p className="text-xs leading-none text-gray-500">
                            Company Code: {company.company_code}
                          </p>
                        </div>
                      </td>
                      <td className="flex flex-1 items-center justify-center py-4">
                        <center>
                          <CompanyDetailsButton company={company}  />
                        </center>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <TableFooter
              range={range}
              slice={slice}
              setPage={setPage}
              page={page}
            />
          </>
        )}
      </div>
    </div>
  );
}
