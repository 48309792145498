import React, { useEffect, useState } from "react";
import { GET_NOTIFICATIONS_URL } from "../../utils/URLS";
import axios from "axios";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../Context/UserContext";

export default function Notifications() {
  const [loading, setLoading] = useState(true);
  const [user] = useUserContext();
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(GET_NOTIFICATIONS_URL, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });
      setNotifications(response.data);
      setLoading(false);
    };
    if (user)
      if (user.groups.includes("administrator")) {
        fetchData();
      } else {
        navigate("/signin");
      }
    //eslint-disable-next-line
  }, []);

  return (
    <div className="mt-16">
      <div className="flex flex-col gap-5 rounded-xl bg-white p-6 shadow">
        <div className="-mt-16 mb-5 rounded-xl bg-golden px-4 py-6 text-2xl font-semibold text-white shadow-md ">
          <p>Notifications</p>
        </div>

        {loading ? (
          <div className="flex h-full w-full items-center justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            {notifications.length > 0 ? (
              <div className="flex flex-col gap-5">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="py-3 text-left text-sm text-gray-400">
                        Title
                      </th>
                      <th className="py-3 text-left text-sm text-gray-400">
                        Type
                      </th>
                      <th className="py-3 text-left text-sm text-gray-400">
                        Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {notifications.map((notification) => {
                      const dateTime = new Date(notification.created_date);
                      const options = {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      };
                      const formattedDate = dateTime.toLocaleString(
                        "en-Gb",
                        options,
                      );
                      return (
                        <tr
                          key={notification.title}
                          className="border-t border-gray-300"
                        >
                          <td className="py-4 text-sm font-medium">
                            {notification.title}
                          </td>
                          <td className="py-4 text-sm font-medium">
                            {notification.title}
                          </td>
                          <td className="py-4 text-sm font-medium">
                            {formattedDate}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="p-5 text-center text-xl font-bold">
                No Notifications
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
