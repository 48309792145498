import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import FolderIcon from "@mui/icons-material/Folder";
import DescriptionIcon from "@mui/icons-material/Description";
import { IconButton, ListItem, ListItemText } from "@mui/material";
import { FILES_URL, ONE_DRIVE_FILES_URL } from "../../utils/URLS";
import DownloadIcon from "@mui/icons-material/Download";
// import { cn } from "../../utils/lib";
import { Button, Flex, Form, Input, Modal, Select } from "antd";
import { useUserContext } from "../../Context/UserContext";
import { useForm } from "antd/es/form/Form";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../components/ui/LoadingSpinner";

const FOLDER_OPTIONS = [
  { value: "Secretarial", label: "Secretarial" },
  { value: "Accounts", label: "Accounts" },
];

export default function ControlledTreeView({ id, name,folder_id }) {
  const [form] = useForm();

  const [expanded, setExpanded] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [folders, setFolders] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [file, setFile] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [fileType, setFileType] = useState("");

  const [loading, setLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [user] = useUserContext();

  const [showFileUploadModal, setShowFileUploadModal] = useState(false);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  const downloadFile = async (fileName, url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobURL = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobURL;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const fetchFolders = async () => {
    setApiLoading(true);
    try {
      // const response = await axios.get(`${FILES_URL}?company_id=${id}`, {
      const response = await axios.get(`${ONE_DRIVE_FILES_URL}?name=${encodeURIComponent(name)}&folder_id=${encodeURIComponent(folder_id)}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });
      setApiLoading(false);
      setFolders(response.data);
    } catch (error) {
      setApiLoading(false);
      console.error("Error fetching folders:", error);
    }
  };

  useEffect(() => {
    fetchFolders();
    // eslint-disable-next-line
  }, [id]);

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      setFileType(uploadedFile.type);
    }
  };

  const submitFileUpload = async (folder) => {
    if (file) {
      const formData = new FormData();
      formData.append("file_field", file);
      formData.append("filename", file.name);
      formData.append("customer_visible", true);
      formData.append("folder_name", folder);
      formData.append("company", id);
      formData.append("user", user?.user?.id);

      setLoading(true);
      try {
        const response = await axios.post(FILES_URL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        });

        if (response.status === 201) {
          setFile("");
          setFileType("");
          setShowFileUploadModal(false);
          form.resetFields();
          fetchFolders();
          toast.success("File uploaded successfully.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        toast.error("Something went wrong uploading file.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  function createDirectoryStructure(parentNode, parentPath) {
    if (parentNode !== undefined) {
      return Object.entries(parentNode).map(([key, folderContent], index) => {
        const currentPath = `${parentPath}/${key}`;
        return (
          <TreeItem
            key={currentPath}
            nodeId={currentPath}
            label={key}
            sx={{ textAlign: "left" }}
            icon={<FolderIcon />}
          >
            {Array.isArray(folderContent) && folderContent.length > 0 ? (
              folderContent.map((file, index1) => (
                <TreeItem
                  key={`${currentPath}-${index1}`}
                  nodeId={`${currentPath}-${index1}`}
                  label={
                    file?.file_name === 'No files to download' ?
                      <ListItemText
                        primary={'No files to download'}
                      /> :
                      <ListItem
                        disablePadding
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="download"
                            onClick={(e) => downloadFile(file.file_name, file.download_url)}
                          >
                            <DownloadIcon />
                          </IconButton>
                        }
                      >
                        <ListItemText
                          primary={file.file_name}
                        // secondary={file.created_date}
                        />
                      </ListItem>
                  }
                  sx={{ textAlign: "left" }}
                  icon={<DescriptionIcon />}
                />
              ))
            ) : (
              createDirectoryStructure(parentNode[key], currentPath)
            )}
          </TreeItem >
        );
      });
    }
  }

  return (
    <Box sx={{ width: "100%", overflowY: 'auto' }}>
      {/* <Box sx={{ width: "100%", mr: 2, mt: -7, mb: 2, pr: 2 }}>
        <div style={{ mr: 10, display: "flex", flexDirection: "row-reverse" }}>
          <button
            className={cn(
              "border border-gray-300 bg-gray-200 px-5 py-4 shadow-sm",
              {
                "border-b-white bg-golden text-white shadow-inner": true,
              },
            )}
            onClick={() => {
              setShowFileUploadModal(true);
            }}
          >
            UPLOAD
          </button>
        </div>
      </Box> */}
      <Box sx={{ display: "flex", minHeight: 270, flexGrow: 1 }}>
        <Box sx={{ width: "100%", mr: 2, border: 1 }}>
          <TreeView
            aria-label="controlled"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            selected={selected}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
          >
            {apiLoading ?
              <div className="flex h-screen items-center justify-center">
                <LoadingSpinner />
              </div> :
              <TreeView
                aria-label="controlled"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                selected={selected}
                onNodeToggle={handleToggle}
                onNodeSelect={handleSelect}
              >
                {createDirectoryStructure(folders[name], "")}
              </TreeView>
            }
          </TreeView >
        </Box>
      </Box>

      <Modal
        title="Upload file."
        onCancel={() => {
          setFile("");
          setFileType("");
          setShowFileUploadModal(false);
          form.resetFields();
        }}
        onOk={submitFileUpload}
        open={showFileUploadModal}
        footer={false}
      >
        <Form
          form={form}
          labelCol={{
            span: 4,
          }}
          onFinish={(values) => {
            submitFileUpload(values.folder);
          }}
        >
          <Form.Item
            label="File"
            name="file"
            rules={[{ required: true, message: "Please input your file!" }]}
          >
            <Input
              type="file"
              onChange={handleFileUpload}
              placeholder="Upload File"
              label="Upload File"
            />
          </Form.Item>
          <Form.Item
            label="Folder"
            name="folder"
            rules={[{ required: true, message: "Select Folder" }]}
          >
            <Select
              options={FOLDER_OPTIONS}
              placeholder="Upload Folder"
              label="Upload Folder"
            />
          </Form.Item>
          <Flex gap={"small"} justify="space-around">
            <Button htmlType="submit" loading={loading} block>
              Upload
            </Button>
          </Flex>
        </Form>
      </Modal>
      <ToastContainer />
    </Box >
  );
}