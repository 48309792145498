import { AppstoreAddOutlined } from "@ant-design/icons"
import { Button, Card, DatePicker, Flex, Input, Select, Space, Table } from "antd"
import React, { Fragment } from "react";
import SeekSolutionApi from "../../../utils/SeekSolutionApi";
import moment from "moment";

const { Column, ColumnGroup } = Table;

const ReminderViewer = ({ company_id }) => {

    const [state, setState] = React.useState([])
    const [remindersState, setRemindersState] = React.useState([])
    const [companyDetail, setCompanyDetail] = React.useState({})
    const [editRow, setEditRow] = React.useState({})
    const [editMode, setEditMode] = React.useState(-1)
    const [loading, setLoading] = React.useState(false)

    SeekSolutionApi.setToken(localStorage.getItem("token"))

    const initialiseReminders = async () => {
        try {
            const apiRes = await SeekSolutionApi.Reminder.list()
            console.log("apiRes", apiRes);
            setRemindersState(apiRes)
        } catch (error) {
            console.log("error", error);
        }
    }
    const initialiseHistory = async (company_id) => {
        try {
            setLoading(true)
            const apiRes = await SeekSolutionApi.Company.reminder(company_id)
            console.log("apiRes", apiRes);
            setState(apiRes.map((res) => ({
                ...res,
                first_reminder: {
                    frequency: res.first_reminder_frequency,
                    number: res.first_reminder_number
                },
                second_reminder: {
                    frequency: res.second_reminder_frequency,
                    number: res.second_reminder_number
                },
                // third_reminder: {
                //     frequency: res.third_reminder_frequency,
                //     number: res.third_reminder_number
                // },
                // fourth_reminder: {
                //     frequency: res.fourth_reminder_frequency,
                //     number: res.fourth_reminder_number
                // },
                // fifth_reminder: {
                //     frequency: res.fifth_reminder_frequency,
                //     number: res.fifth_reminder_number
                // },
            })))
        } catch (error) {
            console.log("error", error);
        } finally {
            setLoading(false)
        }
    }

    const initialiseCompanyDetails = async () => {
        try {
            const apiRes = await SeekSolutionApi.Company.get(company_id)
            console.log("initialiseCompanyDetails apiRes", apiRes);
            setCompanyDetail(apiRes)
            const reminderDAta = await SeekSolutionApi.Reminder.get(company_id)
        } catch (error) {
            console.log("initialiseCompanyDetails error", error);
        }
    }

    const handleNewRow = () => {
        const tempState = state
        const item = {
            "reminder": {
                "id": 1,
                "name": "",
                "description": ""
            },
            "assigned_officer": {
                "id": 1,
                "first_name": "",
                "last_name": ""
            },
            "due_date": "2024-02-08",
            "first_reminder_frequency": "Days",
            "first_reminder_number": null,
            "second_reminder_frequency": "Days",
            "second_reminder_number": null,
            "third_reminder_frequency": "Days",
            "third_reminder_number": null,
            "fourth_reminder_frequency": "Days",
            "fourth_reminder_number": null,
            "fifth_reminder_frequency": "Days",
            "fifth_reminder_number": null,
            "created_date": "2024-02-08T16:42:40.622595Z",
            "company": 113
        }
        if (tempState.length && tempState[0].id) {
            setState([item, ...state])
            setEditMode(0)
        }
    }

    const handleUpdateRow = (name, value) => {
        console.log("handleUpdateRow name", name);
        console.log("handleUpdateRow value", value);
        const tempEditRow = editRow
        tempEditRow[name] = value
        setEditRow(tempEditRow)
    }

    const handleCancel = ({ id }, index) => {
        const tempState = state
        if (!id) {
            tempState.splice(index, 1)
            console.log("tempState", tempState);
            setState([...tempState])
        }
        setEditMode(-1)
    }

    const addUpdate = async (item, index) => {
        try {
            setLoading(true)

            if (item?.id) {
                await SeekSolutionApi.Reminder.edit(item?.id, { company: company_id, reminder: item.id, ...editRow })
            } else {
                await SeekSolutionApi.Reminder.create({ company: company_id, reminder: item.id, ...editRow })
            }
            setEditMode(-1)
            initialiseReminders()

        } catch (error) {
            console.log("error", error);
        } finally {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        initialiseHistory(company_id)
        initialiseReminders()
        initialiseCompanyDetails(company_id)
        // eslint-disable-next-line
    }, [company_id])



    return <Flex>
        <Card title="" extra={<AppstoreAddOutlined onClick={handleNewRow} />} style={{ width: "100%" }}>
            <Table dataSource={state}
                loading={loading}
                scroll={{ x: 1500, y: 1000 }}
                pagination={false}
            >
                <Column
                    title="Reminder Name"
                    dataIndex="reminder"
                    fixed
                    key="reminder"
                    render={(item, record, index) => editMode === index ? <Select
                        defaultValue={item?.id}
                        onChange={(e) => handleUpdateRow("reminder", e)}
                        style={{ width: 150 }}
                        options={remindersState?.map((res) => (
                            { value: res?.id, label: res?.name }
                        ))}
                    /> : item?.name || "Reminder name"}
                />
                <Column
                    title="Due Date"
                    dataIndex="due_date"
                    fixed
                    key="due_date"
                    render={(item, record, index) => (editMode === index ? <DatePicker
                        onChange={(e, dateString) => handleUpdateRow("due_date", moment(dateString).format("YYYY-MM-DD"))}
                    /> : item || "due_date"
                    )}>
                </Column>
                <ColumnGroup title="Reminders">
                    <Column
                        title="Reminder 1"
                        dataIndex="first_reminder"
                        key="first_reminder"
                        render={(item, record, index) => (editMode === index ? <Space.Compact>
                            <Select
                                defaultValue={item?.frequency}
                                onChange={(e) => handleUpdateRow("first_reminder_frequency", e)}
                                style={{ width: 120 }}
                                options={[
                                    { value: 'Days', label: 'Days' },
                                    { value: 'Weeks', label: 'Weeks' },
                                    { value: 'Months', label: 'Months' },
                                    { value: 'Annual', label: 'Annual' },
                                ]}
                            />
                            <Input
                                defaultValue={item?.number}
                                onChange={(e) => handleUpdateRow("first_reminder_number", Number(e.target.value))} />

                        </Space.Compact> : `${item?.number}-${item?.frequency}` || "Reminders 1"
                        )}
                    />
                    <Column
                        title="Reminder 2"
                        dataIndex="second_reminder"
                        key="second_reminder"
                        render={(item, record, index) => (editMode === index ? <Space.Compact>
                            <Select
                                defaultValue={item?.frequency}
                                onChange={(e) => handleUpdateRow("second_reminder_frequency", e)}
                                style={{ width: 120 }}
                                options={[
                                    { value: 'Days', label: 'Days' },
                                    { value: 'Weeks', label: 'Weeks' },
                                    { value: 'Months', label: 'Months' },
                                    { value: 'Annual', label: 'Annual' },
                                ]}
                            />
                            <Input
                                defaultValue={item?.number}
                                onChange={(e) => handleUpdateRow("second_reminder_number", Number(e.target.value))} />

                        </Space.Compact> : `${item?.number}-${item?.frequency}` || "Reminders 1"
                        )}
                    />
                    {/* <Column
                        title="Reminder 3"
                        dataIndex="third_reminder"
                        key="third_reminder"
                        render={(item, record, index) => (editMode === index ? <Space.Compact>
                            <Select
                                defaultValue={item?.frequency}
                                onChange={(e) => handleUpdateRow("third_reminder_frequency", e)}
                                style={{ width: 120 }}
                                options={[
                                    { value: 'Days', label: 'Days' },
                                    { value: 'Weeks', label: 'Weeks' },
                                    { value: 'Months', label: 'Months' },
                                ]}
                            />
                            <Input
                                defaultValue={item?.number}
                                onChange={(e) => handleUpdateRow("third_reminder_number", Number(e.target.value))} />

                        </Space.Compact> : `${item?.number}-${item?.frequency}` || "Reminders 1"
                        )}
                    />
                    <Column
                        title="Reminder 4"
                        dataIndex="fourth_reminder"
                        key="fourth_reminder"
                        render={(item, record, index) => (editMode === index ? <Space.Compact>
                            <Select
                                defaultValue={item?.frequency}
                                onChange={(e) => handleUpdateRow("fourth_reminder_frequency", e)}
                                style={{ width: 120 }}
                                options={[
                                    { value: 'Days', label: 'Days' },
                                    { value: 'Weeks', label: 'Weeks' },
                                    { value: 'Months', label: 'Months' },
                                ]}
                            />
                            <Input
                                defaultValue={item?.number}
                                onChange={(e) => handleUpdateRow("fourth_reminder_number", Number(e.target.value))} />

                        </Space.Compact> : `${item?.number}-${item?.frequency}` || "Reminders 1"
                        )}
                    />
                    <Column
                        title="Reminder 5"
                        dataIndex="fifth_reminder"
                        key="fifth_reminder"
                        render={(item, record, index) => (editMode === index ? <Space.Compact>
                            <Select
                                defaultValue={item?.frequency}
                                onChange={(e) => handleUpdateRow("fifth_reminder_frequency", e)}
                                style={{ width: 120 }}
                                options={[
                                    { value: 'Days', label: 'Days' },
                                    { value: 'Weeks', label: 'Weeks' },
                                    { value: 'Months', label: 'Months' },
                                ]}
                            />
                            <Input
                                defaultValue={item?.number}
                                onChange={(e) => handleUpdateRow("fifth_reminder_number", Number(e.target.value))} />

                        </Space.Compact> : `${item?.number}-${item?.frequency}` || "Reminders 1"
                        )}
                    /> */}
                </ColumnGroup>
                <Column
                    title="Assigned Officer"
                    dataIndex="assigned_officer"
                    key="assigned_officer"
                    render={(item, record, index) => editMode === index ? <Select
                        defaultValue={item?.id}
                        onChange={(e) => handleUpdateRow("assigned_officer", e)}
                        style={{ width: 150 }}
                        options={companyDetail?.users?.map((res) => (
                            { value: res?.id, label: `${res?.first_name} ${res?.last_name}` }
                        ))}
                    /> : `${item?.first_name} ${item?.last_name}`.trim() || "Assigned Officer"}
                />

                <Column
                    title="Action"
                    key="action"
                    render={(item, record, index) => (
                        <Space size="small">
                            {editMode === index ? <Fragment>
                                <Button onClick={() => addUpdate(record, index)}>{record?.id ? "Update" : "Save"}</Button>
                                <Button danger onClick={() => handleCancel(item, index)}>Cancel</Button>
                            </Fragment> :
                                <Button onClick={() => setEditMode(index)}>Edit</Button>
                            }
                        </Space>
                    )}
                />
            </Table>

        </Card>
    </Flex>

}

export default ReminderViewer