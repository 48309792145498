import React, { useState } from "react";
import Input from "../../components/ui/Input";
import { FormProvider, useForm } from "react-hook-form";
import bg from "../../assets/images/hongkong.jpeg";
import { useUserContext } from "../../Context/UserContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import {
  GET_CURRENT_USER_URL,
  GET_USER_EXISTS_URL,
  SEND_AUTH_CODE_URL,
  VERIFY_AUTH_CODE_URL,
} from "../../utils/URLS";

export default function Signin() {
  const methods = useForm();
  const [error, setError] = useState(null);
  const [state, setState] = useState(false);
  const [saveToken, setSaveToken] = useState(true);
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useUserContext();

  const onSubmit = async (data) => {
    setError(null);
    if (!state) {
      try {
        const params = new URLSearchParams();
        params.append("email", data.email);
        await axios.get(GET_USER_EXISTS_URL, {
          params: params,
        });
        const formData = new FormData();
        formData.append("email", data.email);
        await axios.post(
          SEND_AUTH_CODE_URL,
          formData,
        );
        setState(true);
      } catch (err) {
        if (err?.response?.status === 404) setError("Email doesn't exist!");
        else setError("Error sending auth code, please try again!");
      }
    } else {
      const formData = new FormData();
      formData.append("email", data.email);
      formData.append("token", data.token);
      try {
        const verifyTokenResponse = await axios.post(
          VERIFY_AUTH_CODE_URL,
          formData,
        );
        if (saveToken) {
          localStorage.setItem("token", verifyTokenResponse.data.token);
          localStorage.setItem("email", data.email);
        }
        const userResponse = await axios.get(GET_CURRENT_USER_URL, {
          headers: {
            Authorization: `Token ${verifyTokenResponse.data.token}`,
          },
        });
        setUser(userResponse.data);
        navigate("/dashboard");
      } catch (err) {
        setError("Invalid auth code");
      }
    }
  };

  return (
    <div
      className="relative bg-cover bg-no-repeat"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="absolute z-10 h-screen w-screen bg-[linear-gradient(195deg,rgb(66,66,74)_0%,rgb(25,25,25)_100%)] opacity-[0.5]"></div>
      <div className="relative z-50 flex min-h-screen items-center justify-center">
        <div className="mt-16 flex max-w-[600px] flex-col gap-7 rounded-xl bg-white p-5">
          <div className="-mt-16 rounded-xl bg-golden px-5 py-6 text-center text-2xl font-bold text-white">
            <p>Sign in to Shen LaRoche Portal</p>
          </div>
          {state && (
            <div className="rounded-md bg-green-500 px-4 py-5 font-medium text-white">
              Please check your email for the token
            </div>
          )}
          <FormProvider {...methods}>
            <form
              onChange={() => setError(null)}
              onSubmit={methods.handleSubmit(onSubmit)}
              className="flex flex-col items-center gap-1"
            >
              <Input
                label="Email"
                name="email"
                type="email"
                placeholder="Enter your email"
              />
              {state && (
                <Input
                  name="token"
                  label="Token"
                  placeholder="Enter token sent to your email"
                />
              )}
              {error && (
                <p className="my-1 mr-auto text-xs text-red-500">{error}</p>
              )}
              <div className="my-2 mr-auto flex items-center gap-2">
                <label className="relative flex cursor-pointer items-center">
                  <input
                    type="checkbox"
                    className="peer sr-only"
                    id="saveToken"
                    name="saveToken"
                    checked={saveToken}
                    onChange={() => setSaveToken(!saveToken)}
                  />
                  <div className="peer h-3 w-8 rounded-full bg-gray-300 after:absolute after:left-0 after:top-1/2  after:h-4 after:w-4 after:-translate-y-1/2 after:rounded-full after:bg-white after:shadow-md after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:after:bg-golden"></div>
                  <span className="ms-3 text-xs font-medium text-gray-400 dark:text-gray-500">
                    Remember me
                  </span>
                </label>
              </div>

              <button
                type="submit"
                className="my-1 w-full rounded-xl bg-golden py-3 font-semibold uppercase text-white transition hover:opacity-85 disabled:opacity-50"
                disabled={methods.formState.isSubmitting}
              >
                {state ? "Verify" : " Sign in"}
              </button>
            </form>
            <div>
              <p className="text-center text-sm text-gray-500">
                Don't have an account?{" "}
                <a
                  href="/verify"
                  className="font-semibold text-golden transition hover:underline hover:opacity-85"
                >
                  Sign up
                </a>
              </p>
            </div>
          </FormProvider>
        </div>
        <div className="container absolute bottom-0 left-0 right-0 mx-auto flex max-w-6xl items-center justify-between p-5 text-sm font-semibold text-white">
          <div className="flex gap-1">
            <p>© 2024 made with by </p>
            <a href="/">Kusol Labs</a>
          </div>
          <div className="flex gap-3">
            <a href="/">Shen LaRoche</a>
            <a href="/">About Us</a>
            <a href="/">Blog</a>
          </div>
        </div>
      </div>
    </div>
  );
}
