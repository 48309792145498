import React, { useMemo, useState, useEffect } from "react";
import { AvatarComponent } from "avatar-initials";
import {
  Card,
  Flex,
  Button,
  Modal,
  Tag,
  Typography,
  Space,
  Tooltip,
} from "antd";
import { DeleteFilled, PlusSquareFilled } from "@ant-design/icons";
import { CardContent } from "@mui/material";

import { UPDATE_USER_URL, GET_COMPANIES_URL } from "../../../utils/URLS";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { Meta } = Card;
const { Text } = Typography;

const UserViewer = ({ company }) => {
  const [users, setUsers] = React.useState([]);

  // eslint-disable-next-line
  const [arrow, setArrow] = useState("Show");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState({});

  const getUsers = () => {
    fetch(GET_COMPANIES_URL + company.id + "/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUsers(data.users);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, [company]);

  const updateUser = (id) => {
    fetch(UPDATE_USER_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        user_id: id,
        company_id: company.id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        getUsers();
        toast("Success");
      })
      .catch((error) => {
        console.error("Error:", error);
        toast("Failure to update");
      });
  };

  const showModal = () => {
    setDeleteOpen(true);
  };
  const handleOk = () => {
    updateUser(userToDelete.id);
    setDeleteOpen(false);
  };

  const handleCancel = () => {
    setDeleteOpen(false);
  };

  // eslint-disable-next-line
  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }

    if (arrow === "Show") {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  return (
    <Flex wrap="wrap" gap="small">
      {users.map((user) => {
        return (
          <Card
            key={user.id}
            style={{ width: 400, margin: 10 }}
            actions={[
              user.groups.includes("administrator") ? (
                <Tooltip title="User is staff of ShenlaRoche">
                  <Button disabled icon={<DeleteFilled />}>
                    Delete
                  </Button>
                </Tooltip>
              ) : (
                <Space>
                  &nbsp;
                  {!user.is_active ? <Tag color="red">User Inactive</Tag> : ""}
                  <Tooltip title="Click to add user back.">
                    <Button
                      icon={
                        user.is_active ? <DeleteFilled /> : <PlusSquareFilled />
                      }
                      onClick={() => {
                        setUserToDelete(user);
                        showModal();
                      }}
                    ></Button>
                  </Tooltip>
                </Space>
              ),
            ]}
          >
            <Meta
              avatar={
                <AvatarComponent
                  classes="rounded-full"
                  useGravatar={false}
                  size={44}
                  color="#000000"
                  background="#f1f1f1"
                  fontSize={16}
                  fontWeight={400}
                  offsetY={0}
                  initials={`${user.first_name[0]}${user.last_name[0]}`}
                />
              }
              title={user.first_name + " " + user.last_name}
            ></Meta>
            <CardContent>
              <Text>{user.email}</Text>
              <br />
              <Text>
                {user.last_login
                  ? `Last Logged in: ${new Date(user.last_login).toLocaleDateString("en-GB")}`
                  : ""}
              </Text>
              <br />
              {user.groups.includes("administrator") ? (
                <Tag color="#cba234">Administrator</Tag>
              ) : (
                <Tag></Tag>
              )}
            </CardContent>
          </Card>
        );
      })}

      <Modal
        open={deleteOpen}
        title={`Update user: ${userToDelete.first_name} ${userToDelete.last_name} ?`}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleOk}>Ok</Button>
          </>
        )}
      >
        {userToDelete.is_active ? (
          <p>{`Deleting ${userToDelete.first_name} will prevent user from loging in to the portal.`}</p>
        ) : (
          <p>{`Reactivating ${userToDelete.first_name} will allow user to loging in to the portal.`}</p>
        )}
      </Modal>
      <ToastContainer />
    </Flex>
  );
};

export default UserViewer;
