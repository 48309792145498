import { Link, useLocation } from "react-router-dom";
import { cn } from "../utils/lib";
import { ReactComponent as User } from "../assets/SVG/person.svg";
import { ReactComponent as Cog } from "../assets/SVG/cog.svg";
import { ReactComponent as Notification } from "../assets/SVG/notifications.svg";
import { useUserContext } from "../Context/UserContext";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const { pathname } = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useUserContext();
  const navigate = useNavigate();

  const handleSignOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    setUser(null);
    window.location.href = "https://www.shenlaroche.com/";
  };

  return (
    <div className="iems flex justify-between">
      <div className="flex items-center gap-3">
        <span className="font-medium text-gray-500">Pages</span>
        <span className="font-semibold text-gray-600">/</span>
        <span className="text-xl font-semibold capitalize text-gray-700">
          {pathname.slice(1)}
        </span>
      </div>
      <div className="flex items-center gap-3">
        <button className="transition hover:opacity-80">
          <User className="h-5 w-5" />
        </button>
        <button
          onClick={handleSignOut}
          className="text-sm font-semibold transition hover:opacity-80"
        >
          Sign Out
        </button>
        <button className="transition hover:opacity-80">
          <Cog className="h-4 w-4" />
        </button>
        <button className="transition hover:opacity-80">
          <Link to="/notifications">
            <Notification className="h-5 w-5" />
          </Link>
        </button>
      </div>
    </div>
  );
}

// eslint-disable-next-line no-unused-vars
const NavLink = ({ to, children }) => {
  const { pathname } = useLocation();
  return (
    <Link
      to={to}
      className={cn("rounded-md px-5 py-4 font-medium leading-normal", {
        "bg-golden text-white": pathname === to,
      })}
    >
      {children}
    </Link>
  );
};
