import { useState, useEffect } from "react";
import { cn } from "../utils/lib";
import { ReactComponent as ArrowForward } from "../assets/SVG/arrow_forward.svg";

const calculateRange = (data, rowsPerPage) => {
  const range = [];
  const num = Math.ceil(data.length / rowsPerPage);

  for (let i = 1; i <= num; i++) {
    range.push(i);
  }
  return range;
};

const sliceData = (data, page, rowsPerPage) => {
  return data.slice((page - 1) * rowsPerPage, page * rowsPerPage);
};

const useTable = (data, page, rowsPerPage) => {
  const [tableRange, setTableRange] = useState([]);
  const [slice, setSlice] = useState([]);
  
  useEffect(() => {
    const range = calculateRange(data, rowsPerPage);
    setTableRange([...range]);

    const slice = sliceData(data, page, rowsPerPage);
    setSlice([...slice]);
    // eslint-disable-next-line
  }, [data, setTableRange, page, setSlice]);

  return { slice, range: tableRange };
};

export default useTable;

export const TableFooter = ({ range, setPage, page, slice }) => {
  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1);
    }
  }, [slice, page, setPage]);

  const renderPageButtons = () => {
    const totalPages = range.length;
    const maxVisiblePages = 1;

    const maxBackwardPages = 1;

    let startPage = page - maxVisiblePages;
    let endPage = page + maxVisiblePages;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(maxVisiblePages * 2 + 1, totalPages);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(totalPages - maxVisiblePages * 2, 1);
    }

    const pageButtons = [];

    pageButtons.push(
      <button
        key="backward"
        onClick={() => setPage(startPage - maxBackwardPages)}
        disabled={startPage <= 1}
        className={`${
          startPage <= 1 ? "cursor-not-allowed text-gray-400" : ""
        }`}
      >
        <ArrowForward className="h-4 w-4 rotate-180" />
      </button>,
    );

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          className={cn("", {
            "text-golden": page === i,
          })}
          onClick={() => setPage(i)}
        >
          {i}
        </button>,
      );
    }

    pageButtons.push(
      <button
        key="forward"
        onClick={() => setPage(endPage + 1)}
        disabled={endPage >= totalPages}
        className={`${
          endPage >= totalPages ? "cursor-not-allowed text-gray-400" : ""
        }`}
      >
        <ArrowForward className="h-4 w-4" />
      </button>,
    );

    return pageButtons;
  };

  return (
    <div className="flex items-center justify-center gap-5">
      {renderPageButtons()}
    </div>
  );
};
