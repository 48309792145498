import { Modal, Upload, Button, Table, Row, Col } from "antd"
import { UploadOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react";
import axios from "axios";
import { TASK_FILE_URL } from "../../../utils/URLS";

const columns = [
  {
    title: 'FileLink',
    dataIndex: 'file',
    key: 'fileLink',
  },
  {
    title: 'UserName',
    dataIndex: 'name',
    key: 'userName',
  },
  {
    title: 'CreatedDate',
    dataIndex: 'created_date',
    key: 'createdDate',
  },
];

const FileModal = ({
  title,
  visible,
  setVisible,
  taskId,
}) => {
  const [dataSource, setDataSource] = useState([])

  const list = () => {
    axios
      .get(`${TASK_FILE_URL}?task_id=${taskId}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      })
      .then(res => {
        console.log(res)
        // onSuccess(file);
        console.log(res);
        const { data } = res
        setDataSource(data)
      })
  }

  useEffect(() => {
    if (visible === true) {
      list()
      setFileList([])
    } else {

    }
  }, [visible])
  const [fileList, setFileList] = useState([]);


  const uploadFiles = async () => {
    let arr = [];
    if (fileList?.length !== 0) {
      const data = await handleUpload();
      if (data) {
        list()
        setFileList([])
      } else {

      }
    }
    return arr;
  };

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      console.log(fileList)
      fileList.forEach((file, index) => {
        formData.append("file", file);
        return
      });
      formData.append("taskId", taskId)
      const { data } = await axios.post(
        `${TASK_FILE_URL}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${localStorage.getItem("token")}`
          },
        }
      );
      return data;
    } catch (err) {
      // NotificationManager.error(err?.data?.message || err.message || "Error");
    }
  };

  const property = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file, files) => {
      setFileList([...fileList, ...files]);
      return false;
    },
    fileList,
  };

  const handleClick = async () => {
    const files = await uploadFiles();
    console.log(files)
  }
  return (
    <Modal
      title={title}
      open={visible}
      width={1000}
      onCancel={() => setVisible(false)}
      footer={false}
    >
      <div>

      </div>
      <Row style={{ paddingBottom: 24, paddingTop: 24 }} gutter={12} justify={"space-between"} >
        <Col sm={24} >
          <Upload {...property} listType="picture" multiple>
            <Button icon={<UploadOutlined />} className="p-2">
              Select File
            </Button>
          </Upload>
        </Col>
        {
          fileList.length !== 0 && (
            <Col style={{ paddingTop: 24 }} sm={12} >
              <Button onClick={() => handleClick()} >SAVE</Button>
            </Col>
          )
        }
      </Row>
      <Table columns={columns} dataSource={dataSource} />
    </Modal>
  )

}

export default FileModal