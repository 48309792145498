import React from "react";
import { Card, Col, Empty, Flex, Modal, Row } from "antd";
import TaskCard from "./TaskCard";
import TaskModal from "./TaskModal";
import moment from "moment";
import CommentViwer from "../comments/CommentViwer";
import { AppstoreAddOutlined } from "@ant-design/icons"

export const TASK_TODO_STATUS = "To Do"
export const TASK_IN_PROGRESS_STATUS = "In Progress"
export const TASK_COMPLETED_STATUS = "Done"
const TaskList = ({ tasks, setTasks, updateTask, users, setAddTaskModal }) => {
console.log(tasks.parent_task,"tasks")

// const CategorisedTask=tasks.map((index,parent_task)=>{
//     //console.log(index.parent_task,"index")
//     return index.parent_task

// })
  //console.log("CategorisedTask",CategorisedTask);
    


    const [editTaskModal, setEditTaskModal] = React.useState(null)
    

    const [modalComment, setModalComment] = React.useState(null)

    let todoData = tasks.filter((data) => data.status === TASK_TODO_STATUS);
    let inProgressData = tasks.filter((data) => data.status === TASK_IN_PROGRESS_STATUS);
    let completedData = tasks.filter((data) => data.status === TASK_COMPLETED_STATUS);
   
    const onDragStart = (evt) => {
        let element = evt.currentTarget;
        element.classList.add("dragged");
        evt.dataTransfer.setData("text/plain", evt.currentTarget.id);
        evt.dataTransfer.effectAllowed = "move";
    };
    const onDragEnd = (evt) => {
        evt.currentTarget.classList.remove("dragged");
    };
    const onDragEnter = (evt) => {
        evt.preventDefault();
        let element = evt.currentTarget;
        element.classList.add("dragged-over");
        evt.dataTransfer.dropEffect = "move";
    };
    const onDragLeave = (evt) => {
        let currentTarget = evt.currentTarget;
        let newTarget = evt.relatedTarget;
        if (newTarget.parentNode === currentTarget || newTarget === currentTarget)
            return;
        evt.preventDefault();
        let element = evt.currentTarget;
        element.classList.remove("dragged-over");
    };
    const onDragOver = (evt) => {
        evt.preventDefault();
        evt.dataTransfer.dropEffect = "move";
    };
    const onDrop = async (evt, value, status) => {
        console.log("value", value);
        evt.preventDefault();
        evt.currentTarget.classList.remove("dragged-over");
        let data = evt.dataTransfer.getData("text/plain");
        console.log("data", data, status);
        await _updateTask(data, { status })
    };

    const _updateTask = async (data, items) => {
        let updated = tasks.map((task) => {
            console.log("task", task);
            if (task.id.toString() === data?.toString()) {
                task = { ...task, ...items };
            }
            return task;
        });
        await updateTask(data, items)
        setTasks([...updated]);

    }

    const handleTaskUpdate = async (values) => {
        const payload = JSON.parse(JSON.stringify(values))
        
        payload.due_date = moment(payload.due_date).format("YYYY-MM-DD")

        try {
            // const apiRes = await SeekSolutionApi.Tasks.update(values.id, values)
            // console.log("handleTaskUpdate apiRes", apiRes);
            await _updateTask(payload.id, payload)
            setEditTaskModal(null)
        } catch (error) {
            console.log("handleTaskUpdate error", error);
        }
    }


    React.useEffect(() => {
        if (modalComment != null) {
            setEditTaskModal(null)
        }
    }, [modalComment])

    //console.log("editTaskModal", editTaskModal);
    const isDescriptionPresent = editTaskModal ? editTaskModal.mail_sent : false;
    
    return (
        <Flex className="box_outer" >

            <Row gutter={16}>
                <Col span={8}>
                    {/* <Badge.Ribbon text={TASK_TODO_STATUS}> */}

                    <Flex gap={"small"} vertical
                        className="pending small-box"
                        onDragLeave={(e) => onDragLeave(e)}
                        onDragEnter={(e) => onDragEnter(e)}
                        onDragEnd={(e) => onDragEnd(e)}
                        onDragOver={(e) => onDragOver(e)}
                        onDrop={(e) => onDrop(e, false, TASK_TODO_STATUS)}
                    >
                        
                        <h4>{TASK_TODO_STATUS} </h4>
                        <button style={{"float":"right","margin": 5,backgroundColor: '#e5e7eb',color: '#000',padding:5,border: 'none',cursor:'pointer',borderRadius:2}} onClick={()=>setAddTaskModal(true)}><AppstoreAddOutlined></AppstoreAddOutlined>&nbsp;Add Task</button>
                        {todoData.map((task) => (
                            <TaskCard onDragStart={onDragStart} onDragEnd={onDragEnd} {...task} onClick={(items) => setEditTaskModal({ ...items })} />
                        ))}
                    </Flex>
                    {todoData.length === 0 &&
                        <Flex gap={"small"} vertical
                            className="pending small-box"
                            onDragLeave={(e) => onDragLeave(e)}
                            onDragEnter={(e) => onDragEnter(e)}
                            onDragEnd={(e) => onDragEnd(e)}
                            onDragOver={(e) => onDragOver(e)}
                            onDrop={(e) => onDrop(e, true, TASK_TODO_STATUS)}
                        >
                            <Card
                                style={{ width: 400, marginTop: 16 }}
                            >
                                <Empty />
                            </Card>
                        </Flex>}
                    {/* </Badge.Ribbon> */}

                </Col>
                <Col span={8}>
                    <Flex gap={"small"} vertical
                        className="done small-box"
                        onDragLeave={(e) => onDragLeave(e)}
                        onDragEnter={(e) => onDragEnter(e)}
                        onDragEnd={(e) => onDragEnd(e)}
                        onDragOver={(e) => onDragOver(e)}
                        onDrop={(e) => onDrop(e, true, TASK_IN_PROGRESS_STATUS)}
                    >
                        <h4>{TASK_IN_PROGRESS_STATUS}</h4>
                        {inProgressData.map((task) => (
                            <TaskCard onDragStart={onDragStart} onDragEnd={onDragEnd} {...task} onClick={(items) => setEditTaskModal({ ...items })} />
                        ))}
                    </Flex>
                    {inProgressData.length === 0 &&
                        <Flex gap={"small"} vertical
                            className="done  small-box"
                            onDragLeave={(e) => onDragLeave(e)}
                            onDragEnter={(e) => onDragEnter(e)}
                            onDragEnd={(e) => onDragEnd(e)}
                            onDragOver={(e) => onDragOver(e)}
                            onDrop={(e) => onDrop(e, true, TASK_IN_PROGRESS_STATUS)}
                        >
                            <Card
                                style={{ width: 400, marginTop: 16 }}
                            >
                                <Empty />
                            </Card>
                        </Flex>
                    }
                </Col>
                <Col span={8}>
                    <Flex gap={"small"} vertical
                        className="waiting small-box"
                        onDragLeave={(e) => onDragLeave(e)}
                        onDragEnter={(e) => onDragEnter(e)}
                        onDragEnd={(e) => onDragEnd(e)}
                        onDragOver={(e) => onDragOver(e)}
                        onDrop={(e) => onDrop(e, true, TASK_COMPLETED_STATUS)}
                    >
                        <h4>{TASK_COMPLETED_STATUS}</h4>
                        {completedData.map((task) => (
                            <TaskCard onDragStart={onDragStart} onDragEnd={onDragEnd} {...task} onClick={(items) => setEditTaskModal({ ...items })} />
                        ))}
                    </Flex>
                    {completedData.length === 0 &&
                        <Flex gap={"small"} vertical
                            className="waiting small-box"
                            onDragLeave={(e) => onDragLeave(e)}
                            onDragEnter={(e) => onDragEnter(e)}
                            onDragEnd={(e) => onDragEnd(e)}
                            onDragOver={(e) => onDragOver(e)}
                            onDrop={(e) => onDrop(e, true, TASK_COMPLETED_STATUS)}
                        >
                            <Card
                                style={{ width: 400, marginTop: 16 }}
                            >
                                <Empty />
                            </Card>
                        </Flex>
                    }
                </Col>
            </Row>

        
            <TaskModal
               
                modalTitle= {editTaskModal?.parent_task ? `Edit child task of parent task '${editTaskModal?.parent_task_tittle}'` : "Edit parent task"}
                // modalTitle= { tasks.parent_task ? "Edit Child Task of Parent() '' " :"Edit Parent Task"}
                modalOpen={editTaskModal}
                setOpenModal={() => setEditTaskModal(null)}
                options={users}
                handleForm={handleTaskUpdate}
                commentMode={setModalComment}
                task_detail = {editTaskModal}
                isDescriptionPresent={isDescriptionPresent} 
            />
            
            <Modal
                title={"Comments"}
                footer={false}
                open={modalComment != null}
                onCancel={() => setModalComment(null)}
                width={1000}
            >
                <CommentViwer data={modalComment} />
            </Modal>
        </Flex>
    );
}

export default TaskList;
